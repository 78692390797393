// Partners

.partners-hero {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 0 0 0;
  .container {
    max-width: 805px; }
  &--title {
    display: inline-block;
    margin-bottom: 50px;
    padding-bottom: 40px;
    position: relative;
    min-width: 250px;
    &:before {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      height: 2px;
      width: 250px;
      max-width: 100%;
      background-color: white; }
    .title {
      max-width: 750px;
      font-family: "Poppins", sans-serif;
      font-size: 40px;
      font-weight: 800;
      font-style: italic;
      line-height: 1.12;
      letter-spacing: 0.85px;
      text-transform: uppercase;
      color: white;
      b, strong {
        display: block;
        color: #fcf4c5; } } }
  &--text {
    max-width: 440px;
    margin-bottom: 200px;
    p {
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: 0.71px;
      color: white;
      margin-bottom: 20px; } }
  &--info {
    background-color: rgba(84, 188, 189, 0.86);
    padding: 60px 0;
    p {
      margin-bottom: 30px;
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: 0.71px;
      color: white; }
    a {
      display: flex;
      &:hover {
        text-decoration: none;
        transition: .3s;
        .title {
          i {
            left: 10px; } } } }
    .title {
      display: flex;
      align-items: center;
      border: 2px solid white;
      padding: 5px 15px;
      font-family: "Poppins", sans-serif;
      font-size: 40px;
      font-weight: 800;
      font-style: italic;
      line-height: 1.12;
      letter-spacing: 0.85px;
      text-transform: uppercase;
      margin-bottom: 20px;
      color: white;
      i {
        font-size: 22px;
        margin-left: 22px;
        transition: .3s;
        position: relative;
        left: 0; }
      b, strong {
        display: block;
        color: #fcf4c5; } }
    .link {
      color: white;
      border-bottom: 1px solid white;
      &:hover {
        color: white; } } } }
