$r-768: 768px;
$r-992: 992px;
$r-1024: 1024px;
$r-1200: 1200px;
$r-1250: 1250px;

body {
  // break max-768
  @media only screen and (max-width: $r-768 - 1) {

    // Coming Soon page

    .header_soon {
      padding: 10px 0;
      .container {
        padding: 0 15px; }
      &__logo {
        a {
          img {
            height: 20px; } } } }
    .btn_header {
      font-size: 8px;
      i {
        margin-left: 5px; } }
    .coming_soon {
      min-height: 100vh;
      margin-top: 43px;
      padding: 30px 0 100px 0;
      &__top {
        .title {
          margin-bottom: 5px;
          font-size: 20px;
          span {
            font-size: 30px; } }
        p {
          margin: 15px 0 50px 0;
          max-width: 50%;
          font-size: 12px; } }

      &__more {
        .bg_rose {
          padding: 30px 15px; }
        .title {
          padding: 10px 15px;
          font-size: 8px; }
        .bg_rose {
          p {
            font-size: 12px; } } } }

    .line_title {
      font-size: 8px;
      font-weight: 900;
      letter-spacing: 2px;
      padding: 5px 0;
      &:before {
        width: 60px;
        height: 1px; } }

    .footer_soon {
      padding: 15px 0;
      &__block {
        p {
          font-size: 12px; }
        form {
          justify-content: space-between;
          margin-bottom: 20px;
          .your-email {
            width: 60%;
            max-width: 100%; }
          input[type='email'] {
            width: 100%;
            height: 35px;
            font-size: 12px;
            margin: 0;
            &::placeholder {
              font-size: 12px; } }
          input[type='submit'] {
            font-size: 12px;
            width: 38%;
            height: 35px; } }
        ul {
          li {
            a {
              width: 25px;
              height: 25px; } } } } }

    // Global

    .link {
      width: 142px; }

    .s_title {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 3.32px;
      color: black;
      margin-bottom: 20px;
      &:before {
        width: 50px;
        height: 4px;
        background-color: black; } }

    .s_text {
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: 0.5px; }

    .header {
      padding: 15px 0;
      &__logo {
        img {
          max-height: 30px; } } }

    .header_meet {
      margin-top: 60px;
      .container {
        padding: 0 60px; }
      &__block {
        height: 45px;
        p {
          font-size: 12px;
          line-height: 1.25;
          letter-spacing: 0.54px;
          font-weight: 500;
          b, strong {
            font-weight: 800; } } } }

    .home_top {
      padding-top: 50px;
      background-image: url("../img/bg_header_top_mobile.jpg") !important;
      .container {
        padding: 0 60px; }
      &__title {
        margin-bottom: 33px;
        .title {
          font-size: 18px;
          line-height: 1.33;
          letter-spacing: 0.31px;
          b, strong {
            font-size: 25px;
            line-height: 0.92;
            letter-spacing: 0.43px; } } }
      &__text {
        p {
          max-width: 237px;
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: 0.57px;
          padding-top: 20px;
          margin-bottom: 55px;
          position: relative;
          &:before {
            position: absolute;
            content: '';
            width: 209px;
            height: 1px;
            top: 0;
            left: 0;
            background-color: white; } } }
      &__link {
        margin-bottom: 65px; }
      &__about {
        background-color: black;
        padding: 45px 0;
        .s_title {
          color: white;
          &:before {
            background-color: white; } } } }

    .home_top {
      &__about {
        .container {
          padding: 0 60px; }
        p {
          color: white;
          border-bottom: 0;
          padding-bottom: 0; }
        .link {
          color: white;
          border-bottom: 1px solid #fff; } } }

    .clients {
      padding: 45px 0;

      .s_title {
        margin-bottom: 35px; }
      &__slider {
        .clients_slider {
          .swiper-slide {
            text-align: center; } }
        .swiper-button-next, .swiper-button-prev {
          display: flex;
          width: 35px;
          height: 35px; }
        .swiper-button-next {
          right: 0; }
        .swiper-button-prev {
          left: 0; } } }

    .kpi {
      padding: 45px 0 10px 0;
      .container {
        padding: 0 60px; }
      .s_title {
        justify-content: flex-start;
        text-align: left; }
      &__wrapper {
        margin-top: 25px; } }

    .kpi_item {
      border: 0;
      box-shadow: none;
      padding: 25px 0 0 0;
      border-top: 1px solid #4f1d51;
      &__top {
        margin-bottom: 20px;
        img {
          max-width: 70px;
          margin-right: 30px; }
        .title {
          font-size: 16px; } }
      &__text {
        p {
          font-size: 14px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 15px; }
        a {
          color: #4f1d51; } } }

    .map {
      padding: 45px 0;
      .s_title, .s_text {
        padding-left: 45px;
        padding-right: 45px; }
      &__block {
        width: 100%;
        img {
          width: 100%; } } }

    .cases {
      padding: 45px 0 10px 0;
      &__wrapper {
        margin-top: 25px; }
      .container {
        .swiper-button-next {
          right: 15px; }
        .swiper-button-prev {
          left: 15px; } } }
    .cases_item {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      max-width: 255px;
      padding: 15px 0 25px 15px;
      margin: 0 auto;
      p {
        font-size: 10px;
        margin-bottom: 15px; }
      a {
        color: #4f1d51;
        border-color: #4f1d51; }
      &__logo {
        width: 70px;
        height: auto;
        padding-top: 20px;
        img {
          height: auto; } }
      &__img {
        width: 150px;
        height: auto;
        display: block;
        margin-bottom: 18px; } }
    .graph {
      .container {
        padding: 0 60px; }
      .title {
        font-size: 37px;
        letter-spacing: 0.64px; }
      p {
        font-size: 27px;
        line-height: 1.2;
        letter-spacing: 0.47px;
        margin-bottom: 30px; }
      &__img {
        height: 250px;
        background-size: cover;
        background-position: bottom;
        img {
          display: none; } } }

    .footer {
      padding: 35px 0 65px 0;
      .container {
        padding: 0 30px; }
      &__form {
        margin-bottom: 35px;
        p {
          font-size: 14px;
          line-height: 1.29;
          margin-bottom: 20px; }
        form {
          flex-direction: column;
          justify-content: flex-start;
          input[type='email'] {
            width: 100%;
            max-width: 300px;
            height: 55px;
            margin-bottom: 15px;
            font-size: 14px;
            &::placeholder {
              font-size: 14px; } }
          input[type='submit'] {
            width: 100%;
            max-width: 300px;
            height: 55px;
            background-color: black;
            border: 2px solid white;
            color: white;
            border-radius: 0; } } }
      &__socials {
        ul {
          flex-direction: column;
          li {
            margin-bottom: 10px; } } }
      &__logo {
        margin-bottom: 25px;
        img {
          height: 25px; } }
      &__nav {
        flex-direction: column;
        padding-top: 0;
        ul {
          li {
            margin-bottom: 10px;
            a {
              font-weight: 500; } } } } }

    // Partners

    .partners-hero {
      padding: 50px 0;
      background-position: inherit;
      min-height: 430px;
      .container {
        padding: 0 60px; }
      &--title {
        margin-bottom: 20px;
        padding-bottom: 33px;
        .title {
          font-size: 25px;
          line-height: 1.12;
          letter-spacing: .43px; } }
      &--text {
        margin-bottom: 0;
        p {
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: .57px; } }
      &--info {
        padding: 45px 0;
        .container {
          padding: 0 60px; }
        p {
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: .57px;
          border-bottom: 0;
          padding-bottom: 0; }
        .title {
          font-size: 25px;
          line-height: 1.12;
          letter-spacing: .43px; } } }

    // About

    .about_top {
      padding: 50px 0;
      background-position: inherit;
      min-height: 430px;
      .container {
        padding: 0 60px; }
      &__title {
        margin-bottom: 20px;
        padding-bottom: 33px;
        .title {
          font-size: 25px;
          line-height: 1.12;
          letter-spacing: .43px; } }
      &__text {
        margin-bottom: 0;
        p {
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: .57px; } }
      &__takes {
        background-color: black;
        padding: 45px 0;
        .container {
          padding: 0 60px; }
        p {
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: .57px;
          color: white;
          border-bottom: 0;
          padding-bottom: 0; }
        .s_title {
          color: white;
          &:before {
            background-color: white; } } } }

    .leadership {
      padding: 45px 0;
      &__wrapper {
        margin-top: 25px;
        margin-bottom: 0; }
      &__more {
        display: none; } }

    .leadership_item {
      width: 215px;
      height: auto;
      margin: 0 auto;
      flex-direction: column;
      &__img {
        height: 270px;
        position: relative; }
      &__info {
        display: block;
        opacity: 1; } }

    .leadership_item {
      &__info {
        padding: 25px 0 0 0;
        .close {
          display: none; }
        .title {
          padding: 0 15px;
          font-size: 20px; }
        p {
          padding: 0 15px;
          font-size: 14px; }
        .social {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 40px;
          width: 100%;
          margin: 0;
          padding: 0 5px;
          background-color: #62a673;
          border-radius: 0;
          border: 0;
          i {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            width: 30px;
            height: 30px;
            color: #62a673;
            background-color: white; } } } }

    .core_values {
      padding: 45px 0 20px 0;
      .container {
        padding: 0 60px;
        .s_title {
          justify-content: flex-start; } }
      &__wrapper {
        margin-top: 25px; } }

    .core_values_wrap {
      &__item {
        padding-top: 20px;
        border-top: 1px solid $purple;
        .title {
          font-size: 24px;
          line-height: 1.45;
          letter-spacing: 0.44px;
          margin-bottom: 6px; }
        p {
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: 0.57px; } } }

    .global_reach {
      padding: 45px 0 0 0;
      &__wrapper {
        margin-top: 25px; } }
    .global_reach_item {
      width: 100%;
      box-shadow: none;
      background-color: transparent;
      margin-bottom: 0;
      p, a {
        min-height: auto;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.5; }
      &__text {
        padding: 0; } }

    .three_blocks {
      padding: 45px 0 20px 0;
      background-color: rgba(221, 233, 221, 0.8);
      .container {
        padding: 0 60px; }
      &__item {
        .title {
          font-size: 16px;
          margin-bottom: 20px;
          img {
            max-height: 50px; } }
        p {
          font-size: 14px;
          line-height: 1.5;
          letter-spacing: 0.5px;
          margin-bottom: 15px;
          padding-bottom: 0;
          min-height: auto; }
        .link {
          min-width: 160px; } } }

    // Cases

    .case_studies_top {
      padding: 50px 0;
      min-height: 430px;
      .container {
        padding: 0 60px; }
      &__title {
        margin-bottom: 20px;
        padding-bottom: 25px;
        .title {
          font-size: 25px;
          line-height: 1.12;
          letter-spacing: .43px; } }
      &__text {
        p {
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: .57px; } } }

    .case_studies_list {
      padding: 45px 0;
      .container {
        padding: 0 60px; } }

    .case_studies_wrap {
      flex-direction: column;
      margin-bottom: 35px;
      border-bottom: 4px solid black;
      &__img {
        width: 100%;
        height: 210px;
        order: 1; }
      &__info {
        width: 100%;
        order: 2; } }

    .case_st_info {
      padding: 10px 15px;
      display: flex;
      justify-content: flex-end;
      &__top {
        display: none; }
      p {
        display: none; }
      .link {
        color: black;
        border-color: black; } }

    .modal_case {
      &__close {
        right: initial;
        left: 30px;
        top: 18px;
        z-index: 2; }
      .modal-content {
        border: 0;
        .modal-body {
          padding: 25px 30px 10px 30px;
          .case_st_info {
            &__top {
              display: flex;
              flex-direction: column;
              margin-bottom: 35px;
              .title {
                font-size: 20px;
                padding-top: 5px;
                margin-top: 5px;
                text-align: center;
                span {
                  font-size: 18px; } } }
            &__img {
              display: none; } }
          .case_st_i_list__item {
            margin-bottom: 35px;
            .title {
              font-size: 16px;
              font-weight: 700;
              line-height: 1.67;
              letter-spacing: 2.76px;
              margin-bottom: 20px;
              img {
                max-width: 50px;
                margin-right: 20px; } }
            p {
              font-size: 16px;
              line-height: 1.38;
              letter-spacing: .57px;
              margin-bottom: 25px; }
            .numbers {
              ul {
                li {
                  width: 100%;
                  border-bottom: 1px solid #d2384b;
                  p {
                     width: 115px;
                     font-size: 14px;
                     margin: 0 15px 0 0; }
                  span {
                    font-size: 30px; } } } } } } } }

    // Contact

    .contact_top {
      padding-top: 50px;
      justify-content: flex-start;
      min-height: 430px;
      .container {
        padding: 0 60px; }
      &__title {
        .title {
          font-size: 25px;
          text-align: left; } } }

    .contact_info {
      padding: 45px 0;
      .container {
        padding: 0 60px; }
      .s_text {
        text-align: left; }
      &__wrapper {
        margin-top: 35px;
        form {
          margin: 0 auto;
          input, select, textarea {
            border: 0;
            border-radius: 0;
            margin-bottom: 20px;
            font-size: 14px;
            &::placeholder {
              font-size: 14px; } }
          input, select {
            height: 55px; }
          input[type="submit"] {
            margin-top: 0; } } } }

    .contact_list {
      margin-top: 100px; }

    .contact_item {
      .title {
        font-size: 16px;
        margin-bottom: 20px;
        img {
          max-height: 50px; } }
      p {
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: .5px;
        margin-bottom: 15px;
        padding-bottom: 0; } }

    .contact_offices {
      background-color: #fbbe2d;
      .s_title {
        &:before {
          background-color: black; } }
      .accordion {
        .card {
          &:first-of-type {
            background-color: #ffd165; }
          &:nth-of-type(2) {
            background-color: #ffdfaa; }
          &:nth-of-type(3) {
            background-color: #fff0d6; }
          &:nth-of-type(4) {
            background-color: #fffae4; }
          &:nth-of-type(5) {
            background-color: #fffdf3; }
          .card-header {
            .btn {
              color: black; } } } }
      .global_reach_item {
        background-color: transparent;
        &__text {
          a, p {
            color: black; } } } }

    // Brand awareness

    .brand_top {
      padding: 50px 0 25px 0;
      min-height: 430px;
      .container {
        padding: 0 60px; }
      &__title {
        margin-bottom: 20px;
        padding-bottom: 25px;
        .title {
          font-size: 25px; } }
      &__text {
        p {
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: .57px; } } }

    .brand_bl {
      padding: 45px 0 0 0;
      margin-top: 0;
      .container {
        padding: 0 60px; }
      &_item {
        margin-bottom: 35px; } }

    .brand_bl_text {
      .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.67;
        letter-spacing: 2.76px;
        margin-bottom: 20px;
        font-style: normal;
        img {
          max-width: 50px;
          margin-right: 20px; } }
      p {
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: .57px;
        margin: 0 0 25px 0; }
      &__box {
        flex-direction: column;
        p {
          max-width: 280px;
          width: 100%;
          padding: 10px;
          font-size: 14px; } } }

    .brand_values {
      padding: 45px 0 20px 0;
      background-color: #896688;
      .container {
        padding: 0 60px; } }
    .brand_values_wrap {
      div[class*=col-] {
        &:last-of-type {
          .brand_values_wrap__item {
            margin-bottom: 0;
            border-bottom: 0; } } }
      &__item {
        flex-direction: column;
        border-bottom: 1px solid white;
        margin-bottom: 35px;
        .title {
          font-size: 24px;
          line-height: 1.45;
          letter-spacing: 0.44px;
          margin-bottom: 6px;
          max-width: 100%; }
        p {
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: .57px;
          margin-bottom: 35px; } } }

    .words {
      padding: 45px 0;
      .container {
        padding: 0 60px; }
      &__slider {
        max-width: 540px;
        padding: 0 15px;
        margin-top: 25px; }
      .swiper-container {
        .swiper-slide {
          padding: 0 45px;
          img {
            height: 180px;
            margin-bottom: 35px; }
          p {
            font-size: 16px;
            line-height: 1.38;
            letter-spacing: .57px; }
          span {
            font-size: 16px; } } }
      .swiper-button-next, .swiper-button-prev {
        width: 35px;
        height: 35px; }
      .swiper-button-next {
        right: 10px; }
      .swiper-button-prev {
        left: 10px; } }

    // User

    .users_bl {
      background-color: #fffae4; }
    .users_values {
      background-color: #b27e02; }

    // Career

    .career_top {
      padding: 50px 0 25px 0;
      min-height: 430px;
      .container {
        padding: 0 60px; }
      &__title {
        margin-bottom: 20px;
        padding-bottom: 25px;
        .title {
          font-size: 25px; } }
      &__text {
        p {
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: .57px;
          margin-bottom: 55px; } } }

    .career_text {
      padding: 45px 0;
      .container {
        padding: 0 60px; }
      p {
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: .57px;
        text-align: left; } }

    .career_gallery {
      padding: 45px 0; }

    .career_words {
      .words__slider {
        .swiper-button-prev, .swiper-button-next {
          background-color: #48774e;
          i {
            color: white; } } } }

    .positions {
      padding: 45px 0 0 0;
      .container {
        padding: 0 60px; }
      &__wrapper {
        margin-top: 25px; } }

    .positions_wrap {
      &__item {
        padding: 0;
        box-shadow: none;
        margin-bottom: 0;
        &:hover {
          box-shadow: none; }
        p {
          color: white; }
        a {
          color: white;
          width: 100%;
          border-color: white; } } }

    .career_form {
      padding: 45px 0;
      .container {
        padding: 0 60px;
        .s_text {
          text-align: left; } }
      &__wrapper {
        margin-top: 35px;
        form {
          .wpcf7-form-control-wrap {
            width: 100%; }
          input, select, textarea {
            border: 0;
            border-radius: 0;
            margin-bottom: 20px;
            font-size: 14px;
            &::placeholder {
              font-size: 14px; } }
          input, select {
            height: 55px; }
          input[type="submit"] {
            margin-top: 0; } } } }

    .career_single {
      padding: 45px 0;
      .container {
        padding: 0 60px; }
      &__title {
        .title {
          font-size: 25px; } }
      &__content {
        p {
          font-size: 12px;
          line-height: 1.5;
          letter-spacing: 0.43px; } }
      &__form {
        form {
          input, select {
            border: 1px solid #979797; } } } }

    // Insights

    .insights_top {
      padding: 50px 0 25px 0;
      background-position: right;
      min-height: 430px;
      .container {
        padding: 0 60px; }
      &__title {
        margin-bottom: 20px;
        padding-bottom: 25px;
        .title {
          font-size: 25px; } }
      &__text {
        p {
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: .57px;
          margin-bottom: 55px; } } }

    .insights_list {
      padding: 45px 0;
      .container {
        padding: 0 60px; } }

    .insights_item {
      margin-bottom: 35px;
      &__cat {
        margin-bottom: 10px;
        font-size: 12px; }
      &__img {
        margin-bottom: 10px; }
      &__date {
        margin-bottom: 5px;
        font-size: 12px; }
      &__text {
        p {
          line-height: 1.5;
          letter-spacing: 0.5px; } }
      &__more {
        a {
          font-size: 12px; } } }

    .insights_single {
      padding: 45px 0 0 0;
      .container {
        padding: 0 60px; }
      &__img {
        display: none; }
      &__date {
        font-size: 12px; }
      &__title {
        .title {
          font-size: 14px;
          line-height: 1.5;
          letter-spacing: 0.5px; } }
      &__tags {
        padding-top: 10px;
        margin-top: 5px;
        border-top: 1px solid black;
        border-bottom: 0;
        position: relative;
        .arrow {
          position: absolute;
          left: -45px;
          top: -15px;
          padding-right: 0;
          img {
            width: 32px; } }
        ul {
          padding-bottom: 0;
          justify-content: flex-start;
          flex-wrap: wrap;
          li {
            font-size: 12px;
            line-height: 1;
            letter-spacing: 0.86px; } } }
      &__author {
        .title {
          font-size: 12px;
          font-weight: bold;
          line-height: 1; } }
      &__content {
        p {
          font-size: 12px;
          line-height: 1.5;
          letter-spacing: 0.43px; } }
      &__recommend {
        border-top: 10px solid black;
        background-color: white;
        padding: 45px 60px; } }

    // Technology

    .technology_dash {
      padding: 45px 0;
      .container {
        padding: 0 60px; }
      &__img {
        margin: 25px 0; } } }


  // break max-992
  @media only screen and (min-width: $r-768) and (max-width: $r-992 - 1) {

    // Home

    .home_top {
      .container {
        max-width: 720px; } }
    .header_meet {
      &__block {
        justify-content: space-between;
        a {
          right: 15px; } } }
    .clients {
      &__slider {
        .clients_slider {
          max-width: 575px; } } }
    .footer {
      &__nav {
        justify-content: flex-start;
        ul {
          margin-right: 30px; } } }

    // About

    .about_top {
      .container {
        max-width: 720px; } }

    // Partners

    .partners-hero {
      .container {
        max-width: 720px; } }

    // Cases

    .cases_item {
      margin: 0 auto; }

    .case_studies_top {
      .container {
        max-width: 720px; } }

    // Brand awareness

    .words {
      &__slider {
        max-width: 720px;
        padding: 0 15px; } }

    .brand_top {
      .container {
        max-width: 720px; } } }

  // break max-992
  @media only screen and (max-width: $r-992 - 1) {
    .header {
      &__nav {
        display: none; }
      &__socials {
        display: none; }
      &__mobile {
        display: block;
        padding-right: 15px; } }

    .brand_top {
      padding-left: 0;
      padding-right: 0; }

    .career_form {
      &__wrapper {
        form {
          justify-content: center; } } } }

  // break max-1200
  @media only screen and (max-width: $r-1200 - 1) {
    .header {
      &__logo {
        padding-left: 15px; }
      &__nav {
        ul {
          li {
            margin: 0 5px;
            a {
              font-size: 10px; } } } }
      &__socials {
        a {
          font-size: 10px;
          padding: 0 10px; } } }
    .brand_bl_item {
      img {
        position: initial; } } }


  // break max-1250
  @media only screen and (min-width: $r-992) and (max-width: $r-1200) {
    .clients {
      &__slider {
        .clients_slider {
          max-width: 800px; } } }
    .career_form {
      &__wrapper {
        form {
          .wpcf7-form-control-wrap {
            width: 47%;
            max-width: 100%; }
          input, select {
            width: 100%; } } } }
    .header {
      &__logo {
        width: 80px; }
      &__socials {
        padding: 0 15px;
        a {
          margin: 0;
          padding: 0 5px; } } } }


  // break max-1250
  @media only screen and (min-width: $r-1200) and (max-width: $r-1250 - 1) {
    .header {
      &__nav {
        ul {
          li {
            a {
              font-size: 10px; } } } } } } }
