// Coming soon

.coming_soon {
  margin-top: 94px;
  min-height: 1200px;
  padding: 120px 0 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &__top {
    max-width: 870px!important;
    margin: 0 auto;
    .title {
      font-family: 'Poppins', sans-serif!important;
      color: white;
      font-size: 35px;
      font-weight: 300;
      font-style: normal;
      line-height: 1.37;
      letter-spacing: 0.6px;
      margin-bottom: 20px;
      span {
        display: block;
        font-size: 50px;
        font-weight: 800;
        font-style: italic;
        font-stretch: normal;
        line-height: 0.92;
        letter-spacing: 0.85px; } }
    p {
      max-width: 420px;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.4;
      letter-spacing: 0.71px;
      color: white;
      margin-bottom: 60px; }
    .btn_show {
      display: flex;
      justify-content: center;
      margin-bottom: 70px;
      animation: bounce 2s infinite;
      &.active {
        img {
          transform: rotate(-180deg); } } } }
  &__more {
    width: 100%;
    position: absolute;
    bottom: -250px;
    opacity: 0;
    visibility: hidden;
    left: 0;
    transition: 1s;
    &.show {
      bottom: 0;
      opacity: 1;
      visibility: visible;
      transition: 1s; }
    .title {
      padding: 20px 15px;
      background-color: #fcf4c5;
      text-align: center;
      font-size: 16px;
      font-weight: 800;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.04;
      letter-spacing: 0.96px;
      color: black;
      text-transform: uppercase;
      margin: 0;
      p {
        margin-bottom: 0; }
      i {
        font-weight: 600;
        text-transform: none; }
      a {
        color: black; } }
    .bg_rose {
      background-color: rgba(217, 206, 218, 0.88);
      padding: 100px 15px;
      .title {
        max-width: 870px;
        margin: 0 auto; }
      p {
        max-width: 870px;
        margin: 0 auto;
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.4;
        letter-spacing: 0.71px;
        color: black; } } } }

// Global

.btn_header {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.61;
  letter-spacing: 3.32px;
  text-transform: uppercase;
  padding-bottom: 5px;
  position: relative;
  i {
    position: relative;
    right: 0;
    transition: 0.3s;
    margin-left: 15px; }
  &:hover {
    text-decoration: none;
    transition: 0.3s;
    color: white;
    i {
      right: -5px; } }
  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background-color: white;
    bottom: 0;
    left: 0; } }

.line_title {
  display: flex;
  justify-content: center;
  position: relative;
  font-size: 22.6px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.54;
  letter-spacing: 4.16px;
  color: black;
  text-transform: uppercase;
  padding: 15px 0 25px 0;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    width: 100px;
    height: 3px;
    background-color: black; } }

// Keyframes

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-10px); }
  60% {
    transform: translateY(-5px); } }
