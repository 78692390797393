// Home

.home_top {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 0 0 0;
  .container {
    max-width: 805px; }
  &__title {
    margin-bottom: 50px;
    .title {
      font-family: "Poppins", sans-serif;
      font-size: 25px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.37;
      letter-spacing: 0.6px;
      color: white;
      b, strong {
        font-size: 40px;
        font-weight: 800;
        font-style: italic;
        line-height: 0.92;
        letter-spacing: 0.85px;
        text-transform: uppercase;
        display: block;
        margin-bottom: 15px; } } }
  &__text {
    max-width: 440px;
    p {
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: 0.71px;
      color: white;
      margin-bottom: 63px; } }
  &__link {
    margin-bottom: 80px;
    a {
      color: white;
      border-bottom: 1px solid white;
      &:hover {
        color: white; } } }
  &__down {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    animation: bounce 2s infinite;
    i {
      color: white;
      font-size: 20px; } }
  &__about {
    background-color: rgba(211, 199, 213, 0.88);
    padding: 90px 0;
    p {
      margin-bottom: 30px;
      padding-bottom: 30px; }
    a {
      color: black;
      border-bottom: 1px solid black;
      &:hover {
        color: black; } } } }

// Clients

.clients {
  padding: 90px 0;
  .s_title {
    margin-bottom: 70px; }
  &__slider {
    position: relative;
    .swiper-wrapper {
      align-items: center; }
    .swiper-button-next, .swiper-button-prev {
      display: none;
      align-items: center;
      justify-content: center;
      background-image: none;
      width: 47px;
      height: 47px;
      border-radius: 67px;
      box-shadow: 6px 6px 20px 0 rgba(0, 0, 0, 0.17);
      background-color: #d3c7d5;
      outline: none;
      .swiper-button-next {
        right: 0; }
      .swiper-button-prev {
        left: 0; } }
    .clients_slider {
      max-width: 940px;
      .swiper-slide {
        margin: 0 auto; } } } }

// KPI

.kpi {
  padding: 90px 0 55px 0;
  background-color: #fde1e0;
  .container {
    max-width: 805px; }
  &__wrapper {
    justify-content: center;
    margin-top: 100px;
    div[class*='col-'] {
      margin-bottom: 35px; } } }

.kpi_item {
  box-shadow: 0 6px 14px 3px rgba(0, 0, 0, 0.26);
  background-color: #fde1e0;
  padding: 20px 20px 30px 20px;
  height: 100%;
  max-width: 380px;
  margin: 0 auto;
  transition: 0.3s;
  &:hover {
    transition: 0.3s;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.26); }
  &__top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
    min-height: 75px;
    img {
      max-height: 50px;
      margin-right: 40px; }
    .title {
      max-width: 250px;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 4.24px;
      color: $purple;
      text-transform: uppercase; } }
  &__text {
    p {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.64px;
      color: black;
      padding-bottom: 20px;
      margin-bottom: 20px; }
    a {
      color: black;
      border-bottom: 1px solid black;
      &:hover {
        color: black; } } } }

// Map

.map {
  padding: 90px 0 0 0;
  &__block {
    margin-top: 60px; } }

// Cases

.cases-home {
  background-color: #c5b4c4;
  .insights_item {
    background-color: #c5b4c4; } }

.cases {
  padding: 90px 0;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 60%;
    left: 0;
    top: 0;
    background-color: #c5b4c4; }
  .container {
    position: relative;
    .swiper-button-next, .swiper-button-prev {
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: none;
      width: 35px;
      height: 35px;
      border-radius: 67px;
      box-shadow: 6px 6px 20px 0 rgba(0, 0, 0, 0.17);
      background-color: #896688;
      outline: none;
      color: white;
      top: 50%; }
    .swiper-button-next {
      right: -30px; }
    .swiper-button-prev {
      left: -30px; } }
  &__wrapper {
    margin-top: 50px;
    div[class*='col-'] {
      margin-bottom: 35px; } } }

.cases_item {
  padding: 17px;
  margin: 0 auto;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.43);
  background-color: white;
  max-width: 263px;
  height: 100%;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    transition: 0.3s;
    box-shadow: 0 0 6px 0 rgba(0,0,0,.43); }
  &__logo {
    height: 53px;
    img {
      height: 100%; } }
  &__img {
    height: 250px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px; }
  p {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: 0.36px;
    color: black;
    margin-bottom: 40px; }
  a {
    color: #63a672;
    border-bottom: 1px solid #63a672;
    &:hover {
      color: #63a672; } } }

.cases_slider {
  .swiper-button-next, .swiper-button-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: none;
    width: 35px;
    height: 35px;
    border-radius: 67px;
    box-shadow: 6px 6px 20px 0 rgba(0, 0, 0, 0.17);
    background-color: #896688;
    outline: none;
    color: white;
    top: 30%;
    .swiper-button-next {
      right: 0; }
    .swiper-button-prev {
      left: 0; }
    .clients_slider {
      max-width: 940px; } } }


// Graph

.graph {
  //background-size: cover
  //background-repeat: no-repeat
  //background-position: top
  //position: relative
  //.title
  //  font-family: 'Poppins', sans-serif
  //  font-size: 40px
  //  font-weight: 800
  //  font-style: italic
  //  letter-spacing: 1.71px
  //  color: white
  //  margin: 0
  //p
  //  font-family: 'Poppins', sans-serif
  //  font-size: 25px
  //  font-weight: normal
  //  font-stretch: normal
  //  font-style: normal
  //  line-height: 1.58
  //  letter-spacing: 1.26px
  //  color: white
  //  margin-bottom: 35px
  //a
  //  color: white
  //  border-bottom: 1px solid white
  //  &:hover
  //    color: white
  //&__img
  //  position: absolute
  //  background-position: top
  //  background-size: cover
  //  width: 100%
  //  height: 400px
  //  left: 0
  //  bottom: 0
 }  //  text-align: center

