// Case insights top

.insights_top {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 0 25px 0;
  min-height: 530px;
  .container {
    max-width: 805px; }
  &__title {
    display: inline-block;
    margin-bottom: 50px;
    padding-bottom: 40px;
    position: relative;
    min-width: 250px;
    &:before {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      height: 2px;
      width: 250px;
      max-width: 100%;
      background-color: white; }
    .title {
      font-family: "Poppins", sans-serif;
      font-size: 40px;
      font-weight: 800;
      font-style: italic;
      line-height: 1.12;
      letter-spacing: 0.85px;
      text-transform: uppercase;
      color: white;
      b, strong {
        display: block;
        color: #fcf4c5; } } }
  &__text {
    max-width: 440px;
    p {
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: 0.64px;
      color: white;
      margin-bottom: 63px; } } }

// Insights list

.insights_list {
  padding: 90px 0; }

.insights_item {
  border-top: 1px solid black;
  border-bottom: 2px solid black;
  margin-bottom: 50px;
  &__cat {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 1.1px;
    color: black;
    text-transform: uppercase;
    a {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 1.1px;
      color: black;
      text-transform: uppercase; } }
  &__img {
    width: 100%;
    height: 150px;
    background-size: cover;
    background-position: center;
    margin-bottom: 15px; }
  h3 {
    font-size: 18px;
    font-weight: 900; }
  &__date {
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: black;
    margin-bottom: 12px; }
  &__text {
    p {
      height: 85px;
      overflow: hidden;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.64;
      letter-spacing: 0.5px;
      color: black; } }
  &__more {
    text-align: right;
    margin-bottom: 5px;
    a {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.66;
      letter-spacing: 2.06px;
      color: black;
      text-transform: uppercase;
      transition: 0.3s;
      &:hover {
        transition: 0.3s;
        color: black;
        opacity: 0.7;
        text-decoration: none; } } } }

// Insights single

.container_single {
  max-width: 805px;
  margin: 0 auto; }

.insights_single {
  background-color: #e4f2f3;
  padding: 90px 0;
  &__tags {
    border-bottom: 1px solid black;
    margin-bottom: 45px;
    .arrow {
      position: absolute;
      background: #e4f2f3;
      padding-right: 10px; }
    ul {
      display: flex;
      justify-content: flex-end;
      list-style: none;
      margin: 0;
      padding: 0 0 10px 0;
      li {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 1.1px;
        color: black;
        border-right: 1px solid black;
        padding-right: 10px;
        margin: 0 10px 5px 0;
        &:first-child {
          margin-left: 0; }
        &:last-child {
          border-right: 0;
          padding-right: 0;
          margin-right: 0; } } } }
  &__img {
    margin-bottom: 35px;
    height: 420px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
  &__date {
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: black;
    margin-bottom: 10px; }
  &__title {
    .title {
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1.07px;
      color: black; } }
  &__author {
    margin-bottom: 20px;
    .title {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.67;
      letter-spacing: normal;
      color: black; } }
  &__content {
    margin-bottom: 100px;
    p {
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: .64px;
      color: black;
      margin-bottom: 25px; }
    em {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      padding: 10px 0;
      display: block;
      font-weight: 600; }
    h1, h2, h3, h4, h5, h6 {
      font-weight: 800;
      color: black;
      margin-bottom: 25px; } }
  &__recommend {
    .title {
      width: 100%;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.61;
      letter-spacing: 3.32px;
      color: black;
      text-transform: uppercase;
      margin-bottom: 50px;
      padding-bottom: 10px;
      border-bottom: 2px solid black; }
    .insights_list {
      padding: 0;
      .container {
        padding: 0; } } } }

