// Users

.users_top {
  background-position: top;
  .brand_bl {
    background-color: #fffae4;
    &_text {
      .title {
        color: #b27e02; }
      &__box {
        p {
          border-color: #b27e02;
          color: #b27e02; } } } } }

.users_cases {
  background-color: rgba(255, 223, 170, 0.47);
  &:before {
    display: none; }
  .cases_item {
    background-color: rgba(255, 223, 170, 0.47);
    a {
      color: #b27e02;
      border-color: #b27e02; } }
  .swiper-container {
    .swiper-button-next, .swiper-button-prev {
      background-color: #b27e02; } }
  .swiper-button-next,
  .swiper-button-prev {
    background-color: #b27e02!important; } }
