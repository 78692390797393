// Colors

$yellow: #fed079;
$purple: #4f1d51;
$green: #249256;
$orange: #f5a623;
$red: #e5334b;


// Global

body {
  font-family: 'Raleway', sans-serif!important; }

* {
  box-sizing: border-box; }

input, textarea {
  appearance: none; }

img {
  max-width: 100%; }

.s_title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.74;
  letter-spacing: 3.68px;
  text-align: center;
  color: black;
  text-transform: uppercase;
  position: relative;
  padding-top: 15px;
  margin-bottom: 20px;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    background-color: black;
    height: 3px;
    width: 115px; } }

.s_text {
  max-width: 775px;
  margin: 0 auto;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.64px;
  color: black; }

// Buttons

.link {
  width: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.81;
  letter-spacing: 2.95px;
  text-transform: uppercase;
  transition: 0.3s;
  position: relative;
  i {
    transition: 0.3s;
    position: relative;
    right: 0; }
  &:hover {
    transition: 0.3s;
    text-decoration: none;
    opacity: 0.7;
    i {
      right: 5px; } } }
