// Case studies top

.case_studies_top {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 0 25px 0;
  min-height: 530px;
  .container {
    max-width: 805px; }
  &__title {
    display: inline-block;
    margin-bottom: 50px;
    padding-bottom: 40px;
    position: relative;
    min-width: 250px;
    &:before {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      height: 2px;
      width: 250px;
      max-width: 100%;
      background-color: white; }
    .title {
      font-family: "Poppins", sans-serif;
      font-size: 40px;
      font-weight: 800;
      font-style: italic;
      line-height: 1.12;
      letter-spacing: 0.85px;
      text-transform: uppercase;
      color: white;
      b, strong {
        display: block;
        color: #fcf4c5; } } }
  &__text {
    max-width: 440px;
    p {
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: 0.64px;
      color: white;
      margin-bottom: 20px; } } }

// Case list

.case_studies_list {
  padding: 90px 0 55px 0;
  &__more {
    text-align: center;
    margin-top: 20px;
    img {
      width: 80px; } } }

.case_studies_wrap {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.43);
  background-color: white;
  margin-bottom: 30px;
  transition: 0.3s;
  &:hover {
    transition: 0.3s;
    box-shadow: 0 0 4px 0 rgba(0,0,0,.43); }
  &__info {
    width: 55%;
    padding: 35px 30px; }
  &__img {
    width: 45%;
    background-size: cover;
    background-position: center; } }

.case_st_info {
  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    img {
      min-width: 115px;
      max-width: 115px;
      margin-right: 30px; }
    .title {
      font-family: Poppins, sans-serif;
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: italic;
      line-height: 0.8;
      letter-spacing: -0.43px;
      color: #d2384b;
      text-transform: uppercase;
      span {
        display: block;
        padding-top: 15px;
        margin-top: 15px;
        width: 100%;
        font-size: 22px;
        font-weight: 300;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.09;
        letter-spacing: 0.78px;
        color: #d2384b;
        border-top: 1px solid #d2384b; } } }
  p {
    max-width: 500px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.49;
    letter-spacing: 0.4px;
    color: #999;
    margin-bottom: 30px; }
  a {
    color: #d2384b;
    border-bottom: 1px solid #d2384b;
    &:hover {
      color: black; } }
  &__img {
    position: absolute;
    right: 0;
    bottom: 0;
    img {
      max-width: 240px; } } }

.case_st_i_list {
  &__item {
    margin-bottom: 35px;
    &:last-of-type {
      p {
        max-width: 500px; } }
    .title {
      font-size: 18px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: 4.16px;
      color: #896688;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      img {
        max-width: 47px;
        max-height: 40px;
        margin-right: 25px; } }
    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.61;
      letter-spacing: 0.64px;
      color: black; }
    .numbers {
      max-width: 600px;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        li {
          display: flex;
          align-items: center;
          margin: 0 40px 15px 0;
          &:last-of-type {
            margin-bottom: 0; }
          p {
            font-family: Poppins, sans-serif;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.12;
            letter-spacing: normal;
            text-align: right;
            color: #d2384b;
            margin: 0 15px 0 0;
            max-width: 160px; }
          span {
            font-family: Poppins, sans-serif;
            font-size: 40px;
            font-weight: 800;
            font-stretch: normal;
            font-style: italic;
            line-height: 1.2;
            letter-spacing: normal;
            color: #d2384b; } } } } } }

// Modal

.modal_case {
  &__close {
    position: absolute;
    top: -40px;
    right: 0;
    cursor: pointer;
    img {
      width: 15px; } }
  .modal-dialog {
    max-width: 850px; }
  .modal-content {
    border: solid 7px #d2384b;
    border-radius: 0;
    .modal-body {
      padding: 50px 50px 10px 50px; } } }

