// Footer Soon

.footer_soon {
  position: relative;
  z-index: 1;
  background-color: black;
  padding: 65px 0 35px 0;
  &__block {
    max-width: 870px!important;
    margin: 0 auto;
    p {
      max-width: 650px;
      margin: 0 auto 10px auto;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: 0.71px;
      color: white; }
    form {
      max-width: 650px;
      margin: 0 auto 37px auto;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      .wpcf7-not-valid-tip {
        font-size: 12px; }
      .wpcf7-validation-errors {
        width: 100%;
        border: 1px solid red;
        font-size: 12px;
        color: white;
        margin: 0;
        text-align: center; }
      .wpcf7-mail-sent-ok {
        width: 100%;
        border: 1px solid white;
        font-size: 12px;
        color: white;
        margin: 0;
        text-align: center; }
      input[type="email"] {
        width: 446px;
        max-width: 100%;
        height: 75px;
        border: 0;
        background-color: white;
        padding-left: 18px;
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #9b9b9b;
        margin-right: 10px;
        outline: none;
        &::placeholder {
          font-size: 20px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.2;
          letter-spacing: normal;
          color: #9b9b9b;
          outline: none; } }
      input[type="submit"] {
        font-size: 14px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.6px;
        text-align: center;
        color: white;
        width: 183px;
        max-width: 100%;
        height: 75px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15), 0 0 8px 0 rgba(0, 0, 0, 0.12);
        background-color: #4f1d51;
        border: 0;
        outline: none; } }
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        margin: 0 6px;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          width: 41px;
          height: 41px;
          border-radius: 50%;
          transition: 0.3s;
          &:hover {
            transition: 0.3s;
            background-color: rgba(255, 255, 255, 0.1);
            text-decoration: none;
            i {
              color: white; } } }
        i {
          color: black;
          transition: 0.3s; } } } } }

// Footer

.footer {
  background-color: black;
  padding: 120px 0 300px 0;
  &__wrapper {
    justify-content: space-between; }
  &__form {
    margin-bottom: 40px;
    p {
      font-size: 15px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: white;
      margin-bottom: 30px; }
    form {
      display: flex;
      flex-wrap: wrap;
      .ajax-loader {
        display: none!important; }
      .wpcf7-validation-errors, .wpcf7-mail-sent-ok {
        margin: 30px 0 0 0;
        width: 100%;
        font-size: 12px;
        color: white; }
      .wpcf7-not-valid-tip {
        font-size: 12px;
        position: absolute;
        top: 45px; }
      input {
        border: 0;
        outline: 0; }
      input[type="email"] {
        width: 250px;
        max-width: 100%;
        height: 45px;
        background-color: white;
        margin-right: 10px;
        padding-left: 15px;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #9b9b9b;
        appearance: none;
        border-radius: 0;
        &::placeholder {
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          color: #9b9b9b;
          text-transform: uppercase; } }
      input[type="submit"] {
        width: 100px;
        max-width: 100%;
        height: 45px;
        border-radius: 6px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15), 0 0 8px 0 rgba(0, 0, 0, 0.12);
        background-color: #9b9b9b;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.6px;
        text-align: center;
        color: white;
        text-transform: uppercase;
        transition: 0.3s;
        appearance: none;
        &:hover {
          transition: 0.3s;
          opacity: 0.7; } } } }
  &__socials {
    display: flex;
    img {
      margin-right: 27px;
      height: 22px!important; }
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      li {
        list-style: none;
        margin-right: 8px;
        a {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          border-radius: 50%;
          transition: 0.3s;
          &:hover {
            transition: 0.3s;
            opacity: 0.7;
            text-decoration: none; }
          i {
            color: black;
            font-size: 18px; } } } } }
  &__nav {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin-bottom: 15px;
        a {
          font-family: 'Poppins', sans-serif;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.33;
          letter-spacing: 2px;
          color: white;
          transition: 0.3s;
          &:hover {
            transition: 0.3s;
            opacity: .7;
            text-decoration: none; } } } } } }
