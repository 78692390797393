// Technology

.technology_top {
  background-position: top;
  padding-bottom: 300px;
  .brand_bl {
    background-color: #fadeda;
    margin-top: 100px;
    &_text {
      .title {
        color: #ed676a; }
      &__box {
        p {
          border-color: #ed676a;
          color: #ed676a;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center; } } } } }

// Technology dashboard

.technology_dash {
  padding: 90px 0;
  background-color: white;
  &__img {
    margin: 50px 0;
    text-align: center;
    img {
      max-width: 775px;
      width: 100%; } } }
