// Case studies top

.career_top {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 0 25px 0;
  min-height: 530px;
  .container {
    max-width: 805px; }
  &__title {
    display: inline-block;
    margin-bottom: 50px;
    padding-bottom: 40px;
    position: relative;
    min-width: 250px;
    &:before {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      height: 2px;
      width: 250px;
      max-width: 100%;
      background-color: white; }
    .title {
      font-family: "Poppins", sans-serif;
      font-size: 40px;
      font-weight: 800;
      font-style: italic;
      line-height: 1.12;
      letter-spacing: 0.85px;
      text-transform: uppercase;
      color: white;
      b, strong {
        display: block;
        color: #fcf4c5; } } }
  &__text {
    max-width: 440px;
    p {
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: 0.64px;
      color: white;
      margin-bottom: 63px; } }
  &__link {
    a {
      color: white;
      border-bottom: 1px solid white;
      &:hover {
        color: white; } } } }

// Career text

.career_text {
  padding: 90px 0;
  background-color: rgba(242, 248, 242, 0.88);
  .container {
    max-width: 805px; }
  p {
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.64px;
    color: black;
    margin-bottom: 25px;
    text-align: left; } }

// Career gallery

.career_gallery {
  padding: 90px 0;
  img {
    margin-bottom: 15px; }
  &__slider {
    .swiper-button-next, .swiper-button-prev {
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: none;
      width: 35px;
      height: 35px;
      border-radius: 67px;
      box-shadow: 6px 6px 20px 0 rgba(0, 0, 0, 0.17);
      background-color: #b9d1b8;
      outline: none;
      color: white;
      top: 50%;
      i {
        color: white; } }
    .swiper-container {
      .swiper-wrapper {
        align-items: center; }
      .swiper-slide {
        text-align: center;
        img {
          max-width: 220px;
          margin: 0 auto; } } } } }

// Career words

.career_words {
  background-color: rgba(119, 160, 124, 0.97);
  .s_title {
    color: white;
    &:before {
      background-color: white; } }
  .words__slider {
    .swiper-button-next, .swiper-button-prev {
      background-color: #b9d1b8;
      i {
        color: black; } }
    .swiper-container {
      .swiper-slide {
        p {
          color: white; }
        span {
          color: white; } } } } }

// Positions

.positions {
  padding: 90px 0;
  &__wrapper {
    margin-top: 50px; } }

.positions_wrap {
  div[class*='col-'] {
    margin-bottom: 35px; }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 25px 45px;
    border-radius: 5px;
    box-shadow: 0 4px 12px 0 #d4dce9;
    transition: 0.3s;
    &:hover {
      transition: 0.3s;
      box-shadow: 0 0 4px 0 #d4dce9; }
    .title {
      font-size: 22px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.73px;
      color: black;
      margin-bottom: 15px; }
    p {
      font-size: 14px;
      font-weight: 200;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.64;
      letter-spacing: 0.47px;
      color: #192a42;
      margin-bottom: 25px; }
    a {
      color: #63a672;
      border-bottom: 1px solid #63a672;
      &:hover {
        color: #63a672; } } } }

.career_accordion {
  margin-top: 25px;
  .card {
    border: 0;
    background-color: #249255;
    &:first-child {
      background-color: #249255; }
    &:nth-of-type(2) {
      background-color: #62a673; }
    &:nth-of-type(3) {
      background-color: #90bb95; }
    &:nth-of-type(4) {
      background-color: #b9d1b8; }
    &:nth-of-type(5) {
      background-color: #d4e7d3; }
    .card-header {
      padding: 15px 60px;
      background-color: transparent;
      border-bottom: 0;
      .btn {
        padding: 0;
        margin: 0;
        font-size: 18px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: 0.71px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        width: 100%;
        &:hover {
          text-decoration: none;
          i {
            transform: rotate(180deg); } }
        &.collapsed {
          i {
            transform: rotate(0); } } } }
    .card-body {
      padding: 10px 60px 25px 60px; } } }

// Career form

.career_form {
  padding: 90px 0;
  background-color: rgba(221, 233, 221, 0.56);
  .s_text {
    text-align: center; }
  &__wrapper {
    margin-top: 80px;
    .wpcf7 {
      width: 100%; }
    form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 1036px;
      max-width: 100%;
      margin: 0 auto;
      .ajax-loader {
        display: none!important; }
      .wpcf7-validation-errors, .wpcf7-mail-sent-ok {
        margin: 15px 0 0 0;
        width: 100%;
        font-size: 12px; }
      .wpcf7-not-valid-tip {
        font-size: 12px;
        position: absolute;
        top: 55px; }
      input, select {
        max-width: 100%;
        width: 496px;
        height: 56px;
        border-radius: 6px;
        border: solid 0.5px #979797;
        background-color: white;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #9b9b9b;
        margin-bottom: 25px;
        appearance: none;
        &::placeholder {
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          color: #9b9b9b; }
        &:focus {
          outline: none; } }
      select {
        background: url('../img/icon_select.svg') 96% / 3% no-repeat white; }
      input[type="file"] {
        padding: 14px; }
      input[type="submit"] {
        max-width: 443px;
        background-color: black;
        font-size: 14px;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0.71px;
        text-transform: uppercase;
        color: white;
        margin: 40px auto 0 auto; } } } }

// Career single

.career_single {
  padding: 90px 0 45px 0;
  &__wrapper {
    max-width: 775px;
    margin: 0 auto; }
  &__title {
    margin-bottom: 35px;
    .title {
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 1.07px;
      color: black;
      span {
        font-weight: 500; } } }
  &__content {
    p {
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: .64px;
      color: black;
      margin-bottom: 25px; }
    em {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      padding: 10px 0;
      display: block;
      font-weight: 600; }
    h1, h2, h3, h4, h5, h6 {
      font-weight: 800;
      color: black;
      margin-bottom: 25px; } }
  &__form {
    background-color: white;
    form {
      input, select {
        border: 1px solid #979797; } } }
  &__link {
    a {
      width: 180px; } } }
