// Header soon

.header_soon {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background-color: black;
  padding: 30px 0;
  z-index: 1;
  .container {
    padding: 0 40px; }
  &__logo {
    a {
      img {
        height: 35px;
        &:first-child {
          display: none; } } } } }

// header

.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 11;
  padding: 18px 0;
  background-color: black;
  .container {
    max-width: 1920px; }
  &__logo {
    width: 150px;
    max-width: 100%;
    padding: 0 0 0 30px;
    a {
      &:first-of-type {
        img {
          display: none; } }
      img {
        height: 30px; } } }
  &__nav {
    padding: 0 15px;
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        list-style: none;
        margin: 0 10px;
        &:first-child {
          margin-left: 0; }
        &:last-child {
          margin-right: 0; }
        a {
          font-family: 'Poppins', sans-serif;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: 2.33px;
          text-align: center;
          color: white;
          text-transform: uppercase;
          transition: 0.3s;
          position: relative;
          &:before {
            position: absolute;
            content: '';
            left: 50%;
            bottom: -2px;
            width: 0;
            height: 2px;
            background-color: white;
            transition: 0.3s; }
          &:hover {
            transition: 0.3s;
            opacity: .7;
            text-decoration: none;
            &:before {
              width: 100%;
              left: 0; } } } }
      .current-menu-item {
        a {
          &:before {
            width: 100%;
            left: 0; } } } } }
  &__mobile {
    display: none; }
  &__socials {
    padding: 0 35px 0 15px;
    display: flex;
    align-items: center;
    a {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: 2.33px;
      text-align: right;
      color: $yellow;
      text-transform: uppercase;
      margin: 0 10px;
      transition: 0.3s;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        left: 50%;
        bottom: -2px;
        width: 0;
        height: 2px;
        background-color: $yellow;
        transition: 0.3s; }
      &:hover {
        transition: 0.3s;
        color: $yellow;
        opacity: .7;
        text-decoration: none;
        &:before {
          width: 100%;
          left: 0; } } }
    ul {
      display: flex;
      li {
        list-style: none;
        &:last-child {
          padding-right: 0; }
        a {
          display: flex;
          align-items: center;
          color: white;
          font-size: 16px;
          position: relative;
          &:before {
            display: none; }
          &:hover {
            color: $yellow;
            text-decoration: none; }
          &:before {
            position: absolute;
            content: '';
            left: 0;
            width: 1px;
            height: 100%;
            background-color: white; } } } } } }

// Header meet

.header_meet {
  margin-top: 66px;
  background-color: #fcf4c5;
  .container {
    max-width: 1920px; }
  &__block {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    position: relative;
    .textwidget {
      display: flex;
      align-items: center; }
    p {
      font-size: 15px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.87;
      letter-spacing: 0.54px;
      text-align: center;
      color: black;
      margin: 0; }
    a {
      width: 170px;
      position: absolute;
      right: 60px;
      color: black;
      border-bottom: 1px solid black;
      &:hover {
        color: black; } } } }

// Mobile menu

.header__nav_mob {
  display: none;
  position: fixed;
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  max-width: 100%;
  top: 59px;
  background-color: black;
  padding: 0 0 140px 0;
  z-index: 99;
  //-webkit-overflow-scrolling: touch
  ul {
    padding: 0;
    margin: 0;
    flex-direction: column;
    li {
      display: flex;
      width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 70px;
      a {
        width: 100%;
        height: 100%;
        color: transparent; }
      &:first-child {
        background-image: url("../img/bg_home_nav.jpg"); }
      &:nth-of-type(2) {
        background-image: url("../img/bg_user_nav.jpg"); }
      &:nth-of-type(3) {
        background-image: url("../img/bg_brand_nav.jpg"); }
      &:nth-of-type(4) {
        background-image: url("../img/bg_tech_nav.jpg"); }
      &:nth-of-type(5) {
        background-image: url("../img/bg_about_nav.jpg"); }
      &:nth-of-type(6) {
        background-image: url("../img/bg_blog_nav.jpg"); }
      &:nth-of-type(7) {
        background-image: url("../img/bg_case_nav.jpg"); }
      &:nth-of-type(8) {
        background-image: url("../img/bg_contact_nav.jpg"); }
      &:nth-of-type(9) {
        background-image: url("../img/bg_careers.nav.jpg"); } } } }
