// Contact top

.contact_top {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 0 25px 0;
  min-height: 530px;
  .container {
    max-width: 805px; }
  &__title {
    display: inline-block;
    margin-bottom: 50px;
    padding-bottom: 40px;
    position: relative;
    min-width: 250px;
    .title {
      font-family: "Poppins", sans-serif;
      font-size: 40px;
      font-weight: 800;
      font-style: italic;
      line-height: 1.12;
      letter-spacing: 0.85px;
      text-transform: uppercase;
      color: white;
      b, strong {
        display: block;
        color: #fcf4c5; } } } }

// Contact info

.contact_info {
  padding: 90px 0;
  background-color: rgba(254, 223, 169, 0.56);
  .s_title {
    &:before {
      background-color: #f9b001; } }
  .s_text {
    text-align: center; }
  &__wrapper {
    margin-top: 55px;
    form {
      max-width: 444px;
      .ajax-loader {
        display: none!important; }
      .wpcf7-validation-errors, .wpcf7-mail-sent-ok {
        margin: 15px 0 0 0;
        width: 100%;
        font-size: 12px; }
      .wpcf7-not-valid-tip {
        font-size: 12px;
        position: absolute;
        top: 33px; }
      input, select, textarea {
        width: 100%;
        height: 45px;
        border-radius: 4.8px;
        border: solid 1px rgba(151, 151, 151, 0.55);
        background-color: white;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #9b9b9b;
        padding: 0 15px;
        margin-bottom: 25px;
        appearance: none;
        &:focus {
          border-color: #979797;
          outline: none; }
        &::placeholder {
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          color: #9b9b9b; } }
      select {
        background: url('../img/icon_select.svg') 96%/3% no-repeat #fff; }
      textarea {
        padding: 15px;
        min-height: 130px;
        resize: none; }
      input[type="submit"] {
        background-color: black;
        color: white;
        font-size: 14px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.57px;
        text-transform: uppercase;
        border: 0;
        margin-top: 15px; } } } }

.contact_item {
  margin-bottom: 35px;
  .title {
    min-height: 70px;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 2.76px;
    color: #f9b001;
    text-transform: uppercase;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    img {
      max-height: 70px;
      margin-right: 20px; } }
  p {
    max-width: 315px;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: 0.54px;
    color: black;
    margin-bottom: 30px;
    a {
      font-weight: 800;
      color: black;
      &:hover {
        color: black;
        text-decoration: underline; } } }
  .link {
    display: inline-flex;
    padding-right: 25px;
    min-width: 160px;
    width: auto;
    color: black;
    border-bottom: 1px solid black;
    i {
      right: -25px; } } }

// Contact offices

.contact_offices {
  background-color: #fffae4;
  .s_title {
    color: black;
    &:before {
      background-color: #f9b001; } }
  .global_reach_item {
    background-color: #fffae4; }
  .global_reach_item__text {
    .title, p, a {
      color: black; } } }
