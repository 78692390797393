// About top

.about_top {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 0 0 0;
  .container {
    max-width: 805px; }
  &__title {
    display: inline-block;
    margin-bottom: 50px;
    padding-bottom: 40px;
    position: relative;
    min-width: 250px;
    &:before {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      height: 2px;
      width: 250px;
      max-width: 100%;
      background-color: white; }
    .title {
      font-family: "Poppins", sans-serif;
      font-size: 40px;
      font-weight: 800;
      font-style: italic;
      line-height: 1.12;
      letter-spacing: 0.85px;
      text-transform: uppercase;
      color: white;
      b, strong {
        display: block;
        color: #fcf4c5; } } }
  &__text {
    max-width: 440px;
    margin-bottom: 200px;
    p {
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: 0.71px;
      color: white;
      margin-bottom: 20px; } }
  &__takes {
    background-color: rgba(180, 205, 180, 0.88);
    padding: 90px 0;
    p {
      margin-bottom: 20px;
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: 0.71px;
      color: black; }
    a {
      color: black;
      border-bottom: 1px solid black;
      &:hover {
        color: black; } } } }

// Leadership

.leadership {
  padding: 90px 0 70px 0;
  background-color: rgba(240, 247, 240, 0.83);
  &__wrapper {
    max-width: 900px;
    margin: 50px auto 60px auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
  &__more {
    text-align: center;
    img {
      width: 80px; } } }

.leadership_item {
  width: 250px;
  height: 300px;
  margin: 0 7px 13px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  position: relative;
  cursor: pointer;
  &.active {
    .leadership_item__info {
      opacity: 1;
      .top {
        opacity: 1;
        transform: translateY(0); }
      .bottom {
        opacity: 1;
        transform: translateY(0); } } }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center; }
  &__info {
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    position: relative;
    padding: 60px 15px 15px 15px;
    text-align: center;
    transition: 0.3s;
    .close {
      position: absolute;
      right: 3px;
      top: 3px;
      opacity: 1; }
    .top {
      transform: translateY(-55%);
      transition: all 0.3s 0.2s;
      position: absolute;
      padding: 60px 15px 0 15px;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0; }
    .bottom {
      transform: translateY(45%);
      transition: all 0.3s 0.2s;
      position: absolute;
      padding: 15px;
      bottom: 0;
      left: 0;
      width: 100%;
      opacity: 0; }
    .title {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: 2.95px;
      text-transform: uppercase;
      color: #191919; }
    p {
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      color: black;
      margin-bottom: 18px; }
    .social {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #53bbbc;
      border-radius: 50px;
      width: 32px;
      height: 32px;
      margin: 0 auto;
      color: white;
      background-color: #53bbbc;
      transition: 0.3s;
      &:hover {
        transition: 0.3s;
        background-color: rgba(255, 255, 255, 0.95);
        color: #53bbbc;
        text-decoration: none; } } } }

.leadership_slider {
  .swiper-button-next, .swiper-button-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: none;
    width: 35px;
    height: 35px;
    border-radius: 67px;
    box-shadow: 6px 6px 20px 0 rgba(0, 0, 0, 0.17);
    background-color: #62a673;
    outline: none;
    color: white;
    top: 50%;
    .swiper-button-next {
      right: 0; }
    .swiper-button-prev {
      left: 0; }
    .clients_slider {
      max-width: 940px; } } }

// Values

.core_values {
  padding: 90px 0 55px 0;
  &__wrapper {
    margin-top: 50px; } }

.core_values_wrap {
  div[class*=col-] {
    .title {
      color: $green; } }

  &__item {
    margin-bottom: 35px;
    .title {
      font-family: "Poppins", sans-serif;
      font-size: 30px;
      font-weight: 800;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.16;
      letter-spacing: 0.54px;
      text-transform: uppercase;
      margin-bottom: 20px; }
    p {
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: 0.16px;
      color: black; } } }

// Global reach

.global_reach {
  background-color: #77a07c;
  padding: 90px 0;
  .s_title {
    color: white;
    &:before {
      background-color: white; } }
  &__wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; } }

.global_reach_item {
  margin: 0 15px;
  width: 210px;
  box-shadow: 0 7px 18px 3px rgba(0, 0, 0, 0.14);
  background-color: #77a07c;
  margin-bottom: 35px;
  transition: 0.3s;
  &:hover {
    transition: 0.3s;
    box-shadow: 0 0 4px 1px rgba(0,0,0,.14); }
  &__img {
    height: 180px;
    background-size: cover;
    background-position: center; }
  &__text {
    padding: 25px 15px; }
  .title {
    font-size: 16px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: 0.71px;
    color: white;
    margin-bottom: 5px; }
  p, a {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.6px;
    color: white; }
  p {
    margin-bottom: 15px;
    min-height: 30px; }
  a {
    display: block;
    &:hover {
      text-decoration: underline;
      color: white; } } }

.global_accordion {
  margin-top: 25px;
  .card {
    border: 0;
    &:first-child {
      background-color: #249255; }
    &:nth-of-type(2) {
      background-color: #62a673; }
    &:nth-of-type(3) {
      background-color: #90bb95; }
    &:nth-of-type(4) {
      background-color: #b9d1b8; }
    &:nth-of-type(5) {
      background-color: #d4e7d3; }
    .card-header {
      padding: 15px 60px;
      background-color: transparent;
      border-bottom: 0;
      .btn {
        padding: 0;
        margin: 0;
        font-size: 18px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: 0.71px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &:hover {
          text-decoration: none;
          i {
            transform: rotate(180deg); } }
        &.collapsed {
          i {
            transform: rotate(0); } } } }
    .card-body {
      padding: 10px 60px 25px 60px; } } }

// Three blocks

.three_blocks {
  padding: 100px 0 55px 0;
  background-color: rgba(240, 247, 240, 0.82); }

.three_blocks {
  &__item {
    max-width: 285px;
    margin: 0 auto 35px auto;
    .title {
      min-height: 70px;
      font-size: 17px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: 2.76px;
      color: $green;
      text-transform: uppercase;
      margin-bottom: 35px;
      display: flex;
      align-items: center;
      img {
        max-height: 70px;
        margin-right: 20px; } }
    p {
      min-height: 126px;
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: 0.54px;
      color: black;
      margin-bottom: 30px;
      padding-bottom: 30px;
      a {
        font-weight: 800;
        color: black;
        &:hover {
          color: black;
          text-decoration: underline; } } }
    .link {
      color: $green;
      border-bottom: 1px solid $green; } } }

// Trasted partners

.partners {
  padding: 90px 0;
  &__wrapper {
    margin-top: 50px; } }

// Clients about

.clients_about {
  .swiper-button-next, .swiper-button-prev {
    background-color: #b9d1b8; } }


