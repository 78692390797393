// Brand top

.brand_top {
  background-size: cover;
  background-position: center;
  min-height: 727px;
  padding: 120px 60px 0 60px;
  .container {
    max-width: 805px; }
  &__title {
    display: inline-block;
    margin-bottom: 50px;
    padding-bottom: 40px;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      height: 2px;
      width: 250px;
      max-width: 100%;
      background-color: white; }
    .title {
      font-family: "Poppins", sans-serif;
      font-size: 40px;
      font-weight: 800;
      font-style: italic;
      line-height: 1.12;
      letter-spacing: 0.85px;
      text-transform: uppercase;
      color: white;
      b, strong {
        display: block;
        color: #fcf4c5; } } }
  &__text {
    max-width: 440px;
    p {
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: 0.64px;
      color: white;
      margin-bottom: 20px; } } }

// Brand block

.brand_bl {
  padding: 90px 0 0 0;
  margin-top: 175px;
  background-color: #f0ecf1;
  .container {
    max-width: 805px; } }

.brand_bl_item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 70px;
  position: relative;
  img {
    max-width: 90px;
    margin-right: 40px;
    position: absolute;
    left: -130px; }
  &__text {
    width: 100%;
    .title {
      max-width: 775px;
      font-family: Poppins, sans-serif;
      font-size: 30px;
      font-weight: 800;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.16;
      letter-spacing: 5.53px;
      text-transform: uppercase;
      margin-bottom: 20px;
      color: $purple; }
    p {
      max-width: 775px;
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: 0.64px;
      color: black;
      margin-bottom: 20px; } } }

.brand_bl_text {
  &__box {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    p {
      width: 310px;
      margin: 0 30px 15px 0;
      border: 2px solid $purple;
      font-size: 14px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.62;
      letter-spacing: normal;
      color: $purple;
      padding: 15px; } } }

// Words

.words {
  padding: 90px 0;
  background-color: #fce0de;
  &__slider {
    position: relative;
    max-width: 50%;
    margin: 50px auto 0 auto;
    .swiper-button-next, .swiper-button-prev {
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: none;
      width: 47px;
      height: 47px;
      border-radius: 67px;
      box-shadow: 6px 6px 20px 0 rgba(0, 0, 0, 0.17);
      background-color: #896688;
      outline: none;
      color: white;
      top: 30%; }
    .swiper-button-next {
      right: 50px; }
    .swiper-button-prev {
      left: 50px; }
    .swiper-container {
      text-align: center;
      max-width: 900px;
      margin: 0 auto;
      .swiper-slide {
        img {
          height: 250px;
          margin-bottom: 50px; }
        p {
          font-size: 25px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.76;
          letter-spacing: normal;
          color: black;
          text-align: center;
          margin-bottom: 35px; }
        span {
          font-size: 17px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $purple; } } } } }

// Brand values

.brand_values {
  padding: 90px 0 55px 0;
  .container {
    max-width: 1140px; } }

.brand_values_wrap {
  &__item {
    margin-bottom: 35px;
    display: flex;
    .title {
      min-width: 220px;
      max-width: 220px;
      font-family: "Poppins", sans-serif;
      font-size: 30px;
      font-weight: 800;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.16;
      letter-spacing: 0.54px;
      text-transform: uppercase;
      margin-bottom: 20px;
      color: white; }
    p {
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: 0.19px;
      color: white; } } }
